import './Footer.css';

function Footer() {
  return (
    <footer className='contact-container' id='footer'>
      <div className='details'>
        <h2>Fale conosco!</h2>
        <p>Telefone: 47 99984-4419</p>
        <p>Email: administrativo@ragestaoambiental.com.br</p>
        <p>
          Rua Alcida da Silva Teles, 131 - Velha Central - Blumenau/SC - CEP
          89046-400
        </p>
        <div className='divider'></div>

        <p className='rights'>
          Todos os direitos reservados - RA Gestão Ambiental
        </p>
      </div>
    </footer>
  );
}

export default Footer;

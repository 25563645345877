import { UserAddOutlined, CloudUploadOutlined } from '@ant-design/icons';

import '../../styles/homeAdmin.css';
import CardComponent from '../../components/CardComponent/CardComponent';

const HomeAdmin = () => {
  const usersListIcon = '/images/icons/icon-users-list.png';
  const fileListIcon = '/images/icons/icon-file-list.png';

  return (
    <main>
      <div className='cardsContainer'>
        <CardComponent
          route='createUser'
          title='Criação de Usuário'
          CTA='Acessar'
        >
          <UserAddOutlined style={{ fontSize: '100px' }} />
        </CardComponent>
        <CardComponent
          route='uploadFiles'
          title='Envio de Arquivos'
          CTA='Acessar'
        >
          <CloudUploadOutlined style={{ fontSize: '100px' }} />
        </CardComponent>
        <CardComponent
          route='listUsers'
          title='Listagem de Usuário'
          CTA='Acessar'
        >
          <img src={usersListIcon} alt='Icon User list' width='100px' />
        </CardComponent>
        <CardComponent
          route='listFiles'
          title='Listagem de Arquivos'
          CTA='Acessar'
        >
          <img src={fileListIcon} alt='Icon File list' width='100px' />
        </CardComponent>
      </div>
    </main>
  );
};

export default HomeAdmin;

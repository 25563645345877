import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import api from '../services/api';
import moment from 'moment';
import jwt from 'jwt-decode';
type Props = {
  children: JSX.Element | JSX.Element[];
};

type AuthContextProps = {
  authenticated: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogin: (email: string, password: string) => void;
};

const Context = createContext<AuthContextProps>({
  authenticated: false,
  handleLogin: () => {},
  setLoading: () => true,
  loading: true,
});

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tokenExpirationDate = localStorage.getItem('tokenExpirationDate');
    const isTokenExpired = moment(tokenExpirationDate).isBefore(
      moment().utc().format()
    );

    if (token && tokenExpirationDate && !isTokenExpired) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleLogin = async (email: string, password: string) => {
    const body = { Email: email, Password: password };
    const hideMessage = message.loading(
      'Aguarde enquanto efetuamos o login',
      0
    );
    try {
      const {
        data: { token, expirationDate, cnpj, name },
      } = await api.post('/companies/login', body);
      localStorage.setItem('tokenExpirationDate', expirationDate);
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('cnpj', cnpj);
      localStorage.setItem('name', name);
      api.defaults.headers.Authorization = `Bearer ${token}`;

      setIsAuthenticated(true);

      const tokenDecodified: any = jwt(token!);

      message.success('Login efetuado com sucesso!');
      if (tokenDecodified.role && tokenDecodified.role === 'Administrator')
        navigate('/admHome');
      else navigate('/userHome');
    } catch (error: any) {
      console.log(error);
      let errorMessage = error.response.data.errors[0];

      if (errorMessage) message.error(error.response.data.errors[0]);
      else {
        message.error('Por favor, preencha os campos corretamente');
      }
    } finally {
      hideMessage();
    }
  };

  const store = {
    authenticated: isAuthenticated,
    handleLogin: handleLogin,
    loading: loading,
    setLoading: setLoading,
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export { Context, AuthProvider };

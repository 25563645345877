import React, { useState, useEffect } from 'react';
import Navbar from '../components/Home/NavBar/Navbar';
import '../App.css';
import Carousel from '../components/Home/Slider/Slider';
import Institutional from '../components/Home/Institutional/Institutional';
import Solutions from '../components/Home/Solutions/Solutions';
import Footer from '../components/Home/Footer/Footer';
import BackTop from '../components/Home/BackTop/BackTop';

const Home: React.FC = () => {
  const [navBarSticky, setNavBarSticky] = useState(false);
  const [showBackTop, setShowBackTop] = useState(false);

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 140) {
        setNavBarSticky(true);
        setShowBackTop(true);
      } else {
        setNavBarSticky(false);
        setShowBackTop(false);
      }
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <div id='home'>
      <Navbar navBarSticky={navBarSticky} />
      <Carousel />
      <Institutional />
      <Solutions />
      <Footer />
      <BackTop showBackTop={showBackTop} />
    </div>
  );
};

export default Home;

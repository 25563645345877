import React from 'react';
import '../../styles/userHome.css';
import CardComponent from '../../components/CardComponent/CardComponent';

const UserHome: React.FC = () => {
  const iconReport = '/images/icons/icon-report.png';
  const iconClicpboard = '/images/icons/icon-clipboard.png';
  const iconRecyclerPaper = '/images/icons/icon-recycled-paper.png';
  const iconLicense = '/images/icons/license.png';
  const iconCdf = '/images/icons/cdf.png';

  return (
    <main>
      <div className='userCardsContainer'>
        <CardComponent route='mtr' title='MTR' CTA='Acessar'>
          <img src={iconReport} alt='Icon mtr' width='100px' />
        </CardComponent>
        <CardComponent
          route='inventarioResiduos'
          title='Inventário de Resíduos'
          CTA='Acessar'
        >
          <img src={iconClicpboard} alt='Icon Clipboard' width='100px' />
        </CardComponent>
        <CardComponent
          route='relatorioResiduos'
          title='Relatório de Resíduos'
          CTA='Acessar'
        >
          <img src={iconRecyclerPaper} alt='Icon recycle' width='100px' />
        </CardComponent>
        <CardComponent route='cdf' title='CDF' CTA='Acessar'>
          <img src={iconCdf} alt='Icon cdf' width='100px' />
        </CardComponent>
        <CardComponent route='licencas' title='Licenças' CTA='Acessar'>
          <img src={iconLicense} alt='Icon licence' width='100px' />
        </CardComponent>
      </div>
    </main>
  );
};

export default UserHome;

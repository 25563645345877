import "./Institutional.css";

function Institutional() {
  return (
    <>
      <section className="container-institutional" id="institutional">
        <h2>Institucional</h2>

        <div className="institutional-details">
          <div className="institutional-text">
            <p>
              A RA Gestão Ambiental tem sido uma empresa pioneira no setor de
              gestão ambiental, com foco para a indústria têxtil. Seu principal
              objetivo é proporcionar uma destinação adequada para os resíduos
              gerados no processo produtivo, de forma que a empresa possa
              contribuir com a preservação do meio ambiente.
            </p>
            <p>
              Com o passar dos anos, a RA Gestão Ambiental ampliou seu escopo de
              atuação e passou a oferecer uma gestão global de resíduos das mais
              diversas áreas. Dessa forma, a empresa passou a oferecer soluções
              mais abrangentes e diversificadas, capazes de atender às demandas
              específicas de cada cliente.
            </p>
            <p>
              A RA Gestão Ambiental é uma empresa comprometida com a
              sustentabilidade, e suas soluções são diferenciadas e inovadoras.
              A empresa utiliza tecnologias avançadas para a destinação de
              resíduos, sempre com foco na redução dos impactos ambientais e no
              atendimento das condicionantes ambientais.
            </p>
            <p>
              Além da indústria têxtil, a RA Gestão Ambiental também atende
              diversos outros setores industriais e comerciais, oferecendo
              gerenciamento, logística e destinação de resíduos. A empresa é
              licenciada para atuar com resíduos de Classe I, Classe IIA e
              Classe IIB, o que demonstra sua capacidade técnica e sua
              preocupação em atuar de forma segura e responsável.
            </p>
            <p>
              Em resumo, a RA Gestão Ambiental é uma empresa líder no setor de
              gestão ambiental, comprometida com a sustentabilidade e capaz de
              oferecer soluções inovadoras e diferenciadas para a destinação de
              resíduos. Com sua vasta experiência e conhecimento técnico, a
              empresa é capaz de atender às demandas mais exigentes de seus
              clientes, sempre com foco na melhoria contínua e na preservação do
              meio ambiente.
            </p>
          </div>
          <div className="institutional-img">
            <img
              src="/images/institutional/img-institutional.webp"
              alt="Institucional"
            />
          </div>
        </div>

        <div className="divMain-mission-purpose-values">
          <div className="div-mission">
            <img src="/images/icons/icon-value.webp" alt="Mission" />
            <h3>Missão</h3>
            <p>
              Ser referência no gerenciamento de resíduos na região do Vale do
              Itajaí, oferecendo excelência no atendimento aos nossos clientes e
              garantindo a qualidade em todos os processos.
            </p>
          </div>
          <div className="div-values">
            <img src="/images/icons/icon-diamond.webp" alt="Values" />
            <h3>Valor</h3>
            <p>
              Oferecer tranquilidade e confiança para nossos clientes deixarem a
              sua gestão de resíduos e demais trabalhos técnicos da área nas
              mãos de quem tem experiência no que faz. Somos uma empresa moderna
              e nos preocupamos em oferecer um ambiente dinâmico de trabalho,
              que resulta diretamente na qualidade dos nossos serviços.
            </p>
          </div>
          <div className="div-purpose">
            <img src="/images/icons/icon-target.webp" alt="Purpose" />
            <h3>Propósito</h3>
            <p>
              Fornecer soluções integradas no gerenciamento de resíduos, com
              foco na qualidade, credibilidade e melhoria contínua. Fazer a
              diferença para o meio ambiente e para a sociedade através da
              gestão responsável dos resíduos.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Institutional;

import React, { useContext } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Context } from '../context/authContext';

import '../styles/login.css';

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const { handleLogin } = useContext(Context);

  return (
    <div className='appBackground'>
      <Form form={form} layout='vertical' className='loginForm'>
        <Typography.Title>Bem vindo de volta!</Typography.Title>
        <Form.Item
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Por favor informe um email válido',
            },
          ]}
          label='Usuário'
          name={'email'}
        >
          <Input prefix={<MailOutlined />} placeholder='Digite seu email' />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Por favor informe sua senha',
            },
          ]}
          label='Senha'
          name={'password'}
        >
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='Digite sua senha'
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue, getFieldsError }) => {
            const { email, password } = getFieldsValue();
            const hasErrors = getFieldsError().some(
              ({ errors }) => errors.length
            );
            const formIsComplete = !!email && !!password && !hasErrors;
            return (
              <Button
                block
                type='primary'
                htmlType='submit'
                disabled={!formIsComplete}
                onClick={() => {
                  handleLogin(email, password);
                }}
              >
                Entrar
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/authContext';

import './CardComponent.css';

function CardComponent(props: any) {
  const { setLoading } = useContext(Context);
  const navigate = useNavigate();

  const handleRedirect = (route: string) => {
    navigate(route);
    setLoading(true);
  };

  return (
    <div
      className='cardComponentContainer'
      onClick={() => handleRedirect(props.route)}
    >
      <div className='cardComponentHeader'>{props.title}</div>
      <div className='cardComponentBody'>{props.children}</div>
      <div className='cardComponentCTA'>{props.CTA}</div>
    </div>
  );
}

export default CardComponent;

import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import UserHome from './pages/userPages/userHome';
import UserMtr from './pages/userPages/userMtr';
import UserInventarioResiduos from './pages/userPages/userInventarioResiduos';
import UserRelatorioResiduos from './pages/userPages/userRelatorioResiduos';
import UserCdf from './pages/userPages/userCdf';
import UserLicencas from './pages/userPages/userLicencas';
import AdminHome from './pages/adminPages/homeAdmin';
import CreateUser from './pages/adminPages/createUser';
import UploadFiles from './pages/adminPages/uploadFiles';
import ListFiles from './pages/adminPages/listFiles';
import ListUsers from './pages/adminPages/listUsers';
import HeaderUser from './components/headerUser';
import { Context } from './context/authContext';
import { Spin } from 'antd';
import jwt from 'jwt-decode';

const PrivateRoute = ({ children }: any) => {
  const { authenticated, loading } = useContext(Context);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size='large' />
      </div>
    );

  if (!authenticated) return <Navigate to='/login' />;

  return children;
};

const PrivateRouteAdmin = ({ children }: any) => {
  const { authenticated, loading } = useContext(Context);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size='large' />
      </div>
    );

  const token = localStorage.getItem('token');

  if (token) {
    const tokenDecodified: any = jwt(token!);
    if (
      !authenticated ||
      !tokenDecodified.role ||
      tokenDecodified.role !== 'Administrator'
    )
      return <Navigate to='/login' />;

    return children;
  }
  return <Navigate to='/login' />;
};

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route
        element={
          <PrivateRoute>
            <HeaderUser />
          </PrivateRoute>
        }
      >
        <Route path='userHome' element={<UserHome />} />
        <Route path='userHome/mtr' element={<UserMtr />} />
        <Route
          path='userHome/inventarioResiduos'
          element={<UserInventarioResiduos />}
        />
        <Route
          path='userHome/relatorioResiduos'
          element={<UserRelatorioResiduos />}
        />
        <Route path='userHome/cdf' element={<UserCdf />} />
        <Route path='userHome/licencas' element={<UserLicencas />} />
      </Route>
      <Route
        element={
          <PrivateRouteAdmin>
            <HeaderUser />
          </PrivateRouteAdmin>
        }
      >
        <Route path='admHome' element={<AdminHome />} />
        <Route path='admHome/createUser' element={<CreateUser />} />
        <Route path='admHome/uploadFiles' element={<UploadFiles />} />
        <Route path='admHome/listUsers' element={<ListUsers />} />
        <Route path='admHome/listFiles' element={<ListFiles />} />
      </Route>
      <Route path='*' element={<h1>Pagina não encontrada</h1>} />
    </Routes>
  );
}

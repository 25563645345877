import { useState, useEffect } from 'react';
import { Collapse, Spin } from 'antd';
import api from '../../services/api';
import CollapseComponent from '../../components/CollapseComponent/CollapseComponent';
import './accordion.css';
import listOfMonths from './listOfMonths.json';

const { Panel } = Collapse;

const AccordionComponent = (props: any) => {
  const [years, setYears] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const fileType = props.fileType;
  useEffect(() => {
    const getYears = async () => {
      var response = await api.get('blobFiles/years', {
        params: {
          cnpj: localStorage.getItem('cnpj'),
          fileType: fileType,
        },
      });
      setYears(response.data);
      setIsloading(false);
    };
    try {
      getYears();
    } catch (error) {
      console.log(error);
    }
  }, [fileType]);

  return (
    <div style={{ display: 'flex' }}>
      <main className='main'>
        <h1 className='titleMtr'>{props.title}</h1>
        {isLoading ? (
          <Spin size='large' />
        ) : years.length === 0 ? (
          <h1>Nenhum arquivo disponível no momento</h1>
        ) : (
          <Collapse accordion>
            {years.map((year: string, index: number) => {
              return (
                <Panel id={`${year}`} header={year} key={index}>
                  {listOfMonths.map((item: any, index: number) => {
                    return (
                      <div id={item.idMonth} key={index}>
                        <CollapseComponent
                          month={item.displayMonth}
                          monthKey={item.idMonth}
                          fileType={fileType}
                        />
                      </div>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </main>
    </div>
  );
};

export default AccordionComponent;

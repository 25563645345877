import { useState } from 'react';
import './Slider.css';

function Carousel() {
  const [slideIndex, setSlideIndex] = useState(1);

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else {
      setSlideIndex(3);
    }
  };

  const nextSlide = () => {
    if (slideIndex !== 3) {
      setSlideIndex(slideIndex + 1);
    } else {
      setSlideIndex(1);
    }
  };

  const moveDot = (index: any) => {
    setSlideIndex(index);
  };

  return (
    <section className='container-slider'>
      <div className='slider-wrapper'>
        <div className='slider'>
          {Array.from({ length: 3 }).map((item, index) => {
            return (
              <img
                src={`/images/slider/sllider-site-${index + 1}-min.webp`}
                alt={`Ambiente ${index + 1}`}
                id={`slide-${index + 1}`}
                key={index}
                className={
                  slideIndex === index + 1 ? 'slide active-anim' : 'slide'
                }
              />
            );
          })}
        </div>
        <div className='slider-nav'>
          {Array.from({ length: 3 }).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => moveDot(index + 1)}
                className={slideIndex === index + 1 ? 'dot active ' : 'dot'}
              ></div>
            );
          })}
        </div>
        <div className='slider-nav-arrow'>
          <div className='divArrowLeft' onClick={() => prevSlide()}>
            <div className='arrow left'></div>
          </div>
          <div className='divArrowRight' onClick={() => nextSlide()}>
            <div className='arrow right'></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Carousel;

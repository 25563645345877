import logo from '../images/Logo.png';
import { Outlet } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import '../styles/headerUser.css';

const HeaderUser = () => {
  return (
    <>
      <header>
        <div>
          <img src={logo} alt='logo' />
        </div>
        <div className='companyAndIcon'>
          <p className='companyName'>{localStorage.getItem('name')}</p>
          <HomeFilled
            onClick={() => {
              const url = window.location.href;
              const urlLastPart = url.substring(url.lastIndexOf('/') + 1);
              const isHomePage = urlLastPart.toLowerCase().includes('home');

              if (!isHomePage) window.history.go(-1);
            }}
            className='iconHome'
          />
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default HeaderUser;

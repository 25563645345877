import { useEffect, useState, useCallback } from 'react';
import { Table, Modal, message, Button, Input, Select } from 'antd';
import api from '../../services/api';
import { DeleteOutlined } from '@ant-design/icons';

import '../../styles/listPages.css';

const ListUsers = () => {
  const [users, setUsers] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchCnpj, setSearchCnpj] = useState<string>('');
  const [searchIsDeleted, setSearchIsDeleted] = useState<boolean>(false);

  const [refreshTable, setRefreshTable] = useState(true);

  const getUsers = useCallback(
    async (page: number, size: number, cnpj: string, isDeleted: boolean) => {
      const response = await api.get('companies', {
        params: {
          isDeleted: isDeleted,
          cnpj: cnpj,
          size: size,
          page: page,
        },
      });
      setCurrentPage(page);
      setPageSize(size);
      setTotalItems(response.data.totalItems);
      setUsers(response.data.data);
    },
    []
  );

  useEffect(() => {
    try {
      getUsers(currentPage, pageSize, searchCnpj, searchIsDeleted).then(() => {
        setRefreshTable(false);
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, getUsers]);

  const columns = [
    {
      key: '1',
      title: 'Nome da Empresa',
      dataIndex: 'companyName',
    },
    {
      key: '2',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: '3',
      title: 'Cnpj',
      dataIndex: 'cnpj',
    },
    {
      key: '4',
      title: 'Status',
      dataIndex: 'isDeleted',
      render: (isDeleted: boolean) => {
        return <p>{isDeleted ? 'Inativo' : 'Ativo'}</p>;
      },
    },
    {
      key: '5',
      title: 'Excluir Usuário',
      align: 'right' as 'right',
      render: (record: any, row: any) => {
        return (
          <DeleteOutlined
            onClick={() => onDeleteUser(record)}
            style={{
              color: row.isDeleted ? 'grey' : 'red',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'end',
              pointerEvents: row.isDeleted ? 'none' : 'auto',
            }}
          />
        );
      },
    },
  ];

  const optionsIsDisabledSearch = [
    {
      value: false,
      label: 'Ativo',
    },
    {
      value: 'true',
      label: 'Inativo',
    },
  ];

  const handleDelete = async (userId: string) => {
    try {
      await api.delete(`companies/${userId}`);
      setRefreshTable(true);
    } catch (error: any) {
      if (error.response.data.error) message.error(error.response.data.error);
      else
        message.error(
          'Não foi possível deletar usuário, contate o administrador'
        );

      console.log(error);
    }
  };

  const handleClearSearch = () => {
    setSearchCnpj('');
    setSearchIsDeleted(false);
    setRefreshTable(true);
  };

  const onDeleteUser = (user: any) => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir esse usuário?',
      okText: 'Sim',
      cancelText: 'Cancelar',
      okType: 'danger',
      maskClosable: true,
      onOk: () => {
        handleDelete(user.id);
      },
    });
  };

  return (
    <div style={{ margin: '10px' }}>
      <main>
        <div>
          <h1 style={{ color: '#99bd11' }}>Listagem de Usuários</h1>
        </div>
        <div className='filterDiv'>
          <div className='inputWithLabel'>
            <label className='labelSearch' htmlFor='fieldCnpj'>
              Status
            </label>
            <Select
              className='inputSearch'
              value={searchIsDeleted ? 'Inativo' : 'Ativo'}
              onChange={(e: any) => setSearchIsDeleted(e)}
              options={optionsIsDisabledSearch}
            />
          </div>

          <div className='inputWithLabel'>
            <label className='labelSearch' htmlFor='fieldCnpj'>
              Cnpj
            </label>
            <Input
              onChange={(e) => setSearchCnpj(e.target.value)}
              id='fieldCnpj'
              className='inputSearch'
              value={searchCnpj}
            />
          </div>
          <Button
            type='primary'
            className='submitButton'
            onClick={() => setRefreshTable(true)}
          >
            Filtrar
          </Button>
          <Button onClick={handleClearSearch}>Limpar Filtros</Button>
        </div>
        <div>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={users}
            loading={refreshTable}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: pageSize,
              locale: { items_per_page: '/ página' },
              pageSizeOptions: ['10', '25', '50', '100'],
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              onChange: (page: number, pageSize: number) => {
                getUsers(page, pageSize, searchCnpj, searchIsDeleted);
              },
            }}
          />
        </div>
      </main>
    </div>
  );
};

export default ListUsers;

import { Form, Input, Button, message, Tooltip } from 'antd';
import {
  LockOutlined,
  MailOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import api from '../../services/api';

import '../../styles/defaultForm.css';
interface RequestCreateUser {
  Email: string;
  Password: string;
  ConfirmPassword: string;
  CompanyName: string;
  Cnpj: string;
}
const CreateUser = () => {
  const handleSubmit = async (request: RequestCreateUser) => {
    const hideMessage = message.loading(
      'Aguarde enquanto criamos o usuário',
      0
    );
    try {
      const response = await api.post('/companies/create', request);
      if (response?.data.isSuccess) {
        message.success('Usuário criado com sucesso');
        form.resetFields();
      }
    } catch (error: any) {
      const errors: Array<any> = error.response.data.errors;
      if (!error.response.data.isSuccess && errors?.length > 0)
        message.error('Ocorreram os seguintes erros: ' + errors.join(', '));
      else {
        message.error('Ocorreu um erro, contate o administrador');
      }
    } finally {
      hideMessage();
    }
  };

  const [form] = Form.useForm();
  return (
    <main>
      <div className='containerForm'>
        <Form form={form} className='form' layout='vertical'>
          <h1 className='title'>Criação de Usuário</h1>
          <Form.Item
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Por favor informe um email válido',
              },
            ]}
            label='Email'
            name={'email'}
          >
            <Input prefix={<MailOutlined />} placeholder='Digite um email' />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe a senha',
              },
            ]}
            label={
              <div>
                Senha{' '}
                <Tooltip title='A senha deve conter ao menos: 6 caracteres, um número (0-9) um caracter não alfanumérico(@, !), um caracter em caixa baixa (a-z) e um caracter em caixa alta (A-Z).'>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            name={'password'}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              placeholder='Digite a senha'
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe a confirmação da senha',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve();

                  return Promise.reject(
                    'As senhas informadas devem ser iguais'
                  );
                },
              }),
            ]}
            label='Confirmar senha'
            name={'confirmPassword'}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              placeholder='Digite a confirmação da senha'
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe o nome da empresa',
              },
            ]}
            label='Nome da empresa'
            name={'company'}
          >
            <Input placeholder='Digite o nome da empresa' />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe o cnpj da empresa',
              },
            ]}
            label='CNPJ'
            name={'cnpj'}
          >
            <Input placeholder='Digite o cnpj da empresa' />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue, getFieldsError }) => {
              const { email, password, confirmPassword, company, cnpj } =
                getFieldsValue();
              const hasErrors = getFieldsError().some(
                ({ errors }) => errors.length
              );
              const formIsComplete =
                !!email &&
                !!password &&
                !!confirmPassword &&
                !!company &&
                !!cnpj &&
                !hasErrors;

              const request: RequestCreateUser = {
                Email: email,
                Cnpj: cnpj,
                CompanyName: company,
                Password: password,
                ConfirmPassword: confirmPassword,
              };
              return (
                <Button
                  className='submitButton'
                  block
                  type='primary'
                  htmlType='submit'
                  disabled={!formIsComplete}
                  onClick={() => {
                    handleSubmit(request);
                  }}
                >
                  Cadastrar
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default CreateUser;

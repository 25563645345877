import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { ResponseInterceptor } from './services/interceptors';
import { AuthProvider } from './context/authContext';
import { ConfigProvider } from 'antd';

const App: React.FC = () => (
  <BrowserRouter>
    <AuthProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#99bd11',
          },
        }}
      >
        <Routes />
        <ResponseInterceptor />
      </ConfigProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;

import { useEffect, useState, useCallback } from 'react';
import { Table, Modal, message, Button, Select, Input } from 'antd';
import api from '../../services/api';
import { DeleteOutlined } from '@ant-design/icons';
import { fileTypes } from '../../utils/fileType';

import '../../styles/listPages.css';

const ListFiles = () => {
  const [files, setFiles] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchCnpj, setSearchCnpj] = useState<string>('');
  const [searchFileType, setSearchFileType] = useState<string>('');
  const [searchIsDeleted, setSearchIsDeleted] = useState<boolean>(false);

  const [refreshTable, setRefreshTable] = useState(true);

  const getFiles = useCallback(
    async (
      page: number,
      size: number,
      cnpj: string,
      isDeleted: boolean,
      fileType: string
    ) => {
      const response = await api.get('blobFiles', {
        params: {
          isDeleted: isDeleted,
          cnpj: cnpj,
          size: size,
          page: page,
          fileType: fileType,
        },
      });
      setCurrentPage(page);
      setPageSize(size);
      setTotalItems(response.data.totalItems);
      setFiles(response.data.data);
    },
    []
  );

  useEffect(() => {
    try {
      getFiles(
        currentPage,
        pageSize,
        searchCnpj,
        searchIsDeleted,
        searchFileType
      ).then(() => {
        setRefreshTable(false);
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, getFiles]);

  const columns = [
    {
      key: '1',
      title: 'Arquivo',
      dataIndex: 'fileName',
    },
    {
      key: '2',
      title: 'Tipo do Arquivo',
      dataIndex: 'fileType',
    },
    {
      key: '3',
      title: 'Ano',
      dataIndex: 'year',
    },
    {
      key: '4',
      title: 'Mês',
      dataIndex: 'month',
    },
    {
      key: '5',
      title: 'Nome da Empresa',
      dataIndex: 'companyName',
    },
    {
      key: '6',
      title: 'Status',
      dataIndex: 'isDeleted',
      render: (isDeleted: boolean) => {
        return <p>{isDeleted ? 'Inativo' : 'Ativo'}</p>;
      },
    },
    {
      key: '7',
      title: 'Excluir Arquivo',
      align: 'right' as 'right',
      render: (record: any, row: any) => {
        return (
          <DeleteOutlined
            onClick={() => onDeleteFile(record)}
            style={{
              color: row.isDeleted ? 'grey' : 'red',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'end',
              pointerEvents: row.isDeleted ? 'none' : 'auto',
            }}
          />
        );
      },
    },
  ];

  const handleDelete = async (userId: string) => {
    try {
      await api.delete(`blobFiles/${userId}`);
      setRefreshTable(true);
    } catch (error: any) {
      if (error.response.data.error) message.error(error.response.data.error);
      else
        message.error(
          'Não foi possível deletar usuário, contate o administrador'
        );

      console.log(error);
    }
  };

  const onDeleteFile = (user: any) => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir esse arquivo?',
      okText: 'Sim',
      cancelText: 'Cancelar',
      okType: 'danger',
      maskClosable: true,
      onOk: () => {
        handleDelete(user.id);
      },
    });
  };

  const optionsIsDisabledSearch = [
    {
      value: false,
      label: 'Ativo',
    },
    {
      value: 'true',
      label: 'Inativo',
    },
  ];

  const optionsFileTypes = [
    {
      value: 'mtr',
      label: 'MTR',
    },
    {
      value: 'cdf',
      label: 'CDF',
    },
    {
      value: 'InventarioResiduos',
      label: 'Inventário de Resíduos',
    },
    {
      value: 'RelatorioResiduos',
      label: 'Relatório de Resíduos',
    },
    {
      value: 'licencas',
      label: 'Licenças',
    },
  ];

  const handleClearSearch = () => {
    setSearchCnpj('');
    setSearchIsDeleted(false);
    setSearchFileType('');
    setRefreshTable(true);
  };

  return (
    <div style={{ margin: '10px' }}>
      <main>
        <div>
          <h1 style={{ color: '#99bd11' }}>Listagem de Arquivos</h1>
        </div>
        <div className='filterDiv'>
          <div className='inputWithLabel'>
            <label className='labelSearch' htmlFor='fieldCnpj'>
              Status
            </label>
            <Select
              className='inputSearch'
              value={searchIsDeleted ? 'Inativo' : 'Ativo'}
              allowClear
              onChange={(e: any) => {
                setSearchIsDeleted(e);
              }}
              options={optionsIsDisabledSearch}
            />
          </div>
          <div className='inputWithLabel'>
            <label className='labelSearch' htmlFor='fieldCnpj'>
              Tipo do arquivo
            </label>
            <Select
              className='inputSearch'
              value={searchFileType}
              allowClear
              onChange={(e: any) => {
                if (e === undefined) setSearchFileType('');

                setSearchFileType(e);
              }}
              options={fileTypes}
            />
          </div>
          <div className='inputWithLabel'>
            <label className='labelSearch' htmlFor='fieldCnpj'>
              Cnpj
            </label>
            <Input
              onChange={(e) => setSearchCnpj(e.target.value)}
              id='fieldCnpj'
              className='inputSearch'
              value={searchCnpj}
            />
          </div>
          <Button
            type='primary'
            className='submitButton'
            onClick={() => setRefreshTable(true)}
          >
            Filtrar
          </Button>
          <Button onClick={handleClearSearch}>Limpar Filtros</Button>
        </div>
        <div>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={files}
            loading={refreshTable}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: pageSize,
              locale: { items_per_page: '/ página' },
              pageSizeOptions: ['10', '25', '50', '100'],
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              onChange: (page: number, pageSize: number) => {
                getFiles(
                  page,
                  pageSize,
                  searchCnpj,
                  searchIsDeleted,
                  searchFileType
                );
              },
            }}
          />
        </div>
      </main>
    </div>
  );
};

export default ListFiles;

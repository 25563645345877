import React from "react";
import { useNavigate } from "react-router-dom";
import "./Button.css";


function Button(props: any) {
  const navigate = useNavigate()
  return (
    <div className={props.class} onClick={() => navigate(`${props.url}`)}>
      <p>{props.children}</p>
    </div>
  );
}

export default Button;

import { useState } from 'react';
import './CollapseComponent.css';
import { FolderFilled } from '@ant-design/icons';
import api from '../../services/api';

function CollapseComponent(props: any) {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<Array<String>>([]);

  const getFiles = async () => {
    const panel = document.getElementsByClassName(
      'ant-collapse-item ant-collapse-item-active'
    );
    const year = panel[0].id;
    const month = props.monthKey;

    try {
      if (!open) {
        const response = await api.get('blobFiles/files', {
          params: {
            cnpj: localStorage.getItem('cnpj'),
            fileType: props.fileType,
            year: year,
            month: month,
          },
        });
        setFiles(response.data);
      }
      setOpen(!open);
    } catch (error) {
      console.log(error);
    }

    setOpen(!open);
  };

  const downloadPDF = async (fileName: String) => {
    const panel = document.getElementsByClassName(
      'ant-collapse-item ant-collapse-item-active'
    );
    const year = panel[0].id;
    const month = props.monthKey;
    const cnpj = localStorage.getItem('cnpj');
    try {
      const response = await api.get('blobFiles/download', {
        params: {
          filePath: `${cnpj}/${props.fileType}/${year}/${month}/${fileName}`,
        },
      });
      const link = document.createElement('a');
      link.href = response.data;
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className='collapse'
      key={props.index}
      onClick={getFiles}
      id={props.month}
    >
      <div className='divTitle'>
        <div className='divSeta'>
          <i className={open ? 'seta down' : 'seta'}></i>
        </div>
        <FolderFilled style={{ fontSize: '20px' }} />
        <p className='titleMonth'>{props.month}</p>
      </div>

      {open && (
        <div className='listFiles-parent'>
          <div className='listFiles'>
            {files.length === 0 ? (
              <p>Nenhum arquivo encontrado!</p>
            ) : (
              <>
                {files.map((item: String, index: number) => {
                  return (
                    <div
                      className='file'
                      onClick={() => downloadPDF(item)}
                      key={index}
                    >
                      <img src='/images/icons/pdf-file.png' alt='PDF' />
                      <p>{item}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CollapseComponent;

import InterfaceCard from './InterfaceCard';

const dataCards: InterfaceCard[] = [
  {
    title: 'Gestão de resíduos têxtil',
    imageUrl: '/images/solutions/img-gestao-residuos.webp',
    text: [
      'Mais de 05 anos em destinação de resíduos têxteis, a RA Gestão Ambiental se tornou uma referência em gestão global de resíduos da indústria têxtil. ',
      'Com sua expertise e compromisso com a sustentabilidade, a RA está pronta para atender às demandas de seus clientes e contribuir para um mundo mais sustentável.',
    ],
  },
  {
    title: 'Gestão de resíduos Industriais',
    imageUrl: '/images/solutions/img-gestao-residuos-industriais.webp',
    text: [
      'A RA Gestão Ambiental se notabiliza por atuar com gerenciamento de resíduos industriais compactáveis (lixo comum), orgânicos, recicláveis, perigosos, lodos, cinza entre outros. ',
      'A empresa dispõe de mão de obra altamente qualificada, além de contar com veículos e equipamentos novos, com tecnologia de ponta, resultando em uma prestação de serviço com elevado padrão de excelência.',
    ],
  },
  {
    title: 'Logística de resíduos',
    imageUrl: '/images/solutions/img-logistica-residuos.webp',
    text: [
      'Disponibilização dos mais diversos coletores para atendimento de praticamente todos os resíduos gerados em estabelecimentos comerciais, conjuntos residenciais, indústria têxtil, entre outros. ',
      'A RA Gestão Ambiental possui frota própria com os mais diversos veículos para atender a demanda dos mais diversos tipos de resíduos gerados.',
    ],
  },
];

export default dataCards;

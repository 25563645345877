import React from 'react';
import Accordion from '../../components/AccordionComponent/Accordion';

const UserRelatorioResiduos: React.FC = () => {
  return (
    <Accordion title='Relatório de Resíduos' fileType='RelatorioResiduos' />
  );
};

export default UserRelatorioResiduos;

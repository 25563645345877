const fileTypes = [
  {
    value: 'mtr',
    label: 'MTR',
  },
  {
    value: 'cdf',
    label: 'CDF',
  },
  {
    value: 'InventarioResiduos',
    label: 'Inventário de Resíduos',
  },
  {
    value: 'RelatorioResiduos',
    label: 'Relatório de Resíduos',
  },
  {
    value: 'licencas',
    label: 'Licenças',
  },
];

export { fileTypes };

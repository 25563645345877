import { useState } from "react";
import Button from "../Button/Button";
import "./Navbar.css";

function Navbar(props: any) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const verifyWindowSize = () => {
    if (window.innerWidth > 960) {
      setClick(false);
    }
  };

  const handleClickLinks = (link: string) => {
    window.event?.preventDefault();
    closeMobileMenu();
    let positionScroll = {
      top: 0
    };
    
    let element = document.getElementById(link)
    let coord = element?.offsetTop
    positionScroll.top = coord! - 80

    window.scroll({
      top: positionScroll.top,
      behavior: "smooth",
    });
  };

  window.addEventListener("resize", verifyWindowSize);

  return (
    <>
      <nav className={props.navBarSticky ? "navbar sticky" : "navbar"}>
        <div className="container-navbar">
          <a
            href="/#"
            onClick={() => handleClickLinks("home")}
            className="navbar-logo">
            <img src="images/ra-logo.jpeg" alt="RA Gestão Ambiental" />
          </a>
          <div className={click ? "nav-menu active" : "nav-menu"}>
            <a href="/#institucional" onClick={() => handleClickLinks("home")}>
              Home
            </a>
            <a href="/#" onClick={() => handleClickLinks("institutional")}>
              Institucional
            </a>
            <a href="/#" onClick={() => handleClickLinks("solutions")}>
              Soluções
            </a>
            <a href="/#" onClick={() => handleClickLinks("footer")}>
              Contato
            </a>
            <Button
              class={click ? "button-ClienteArea-mobile" : "button-ClienteArea"}
              url="/login">
              Área do Cliente
            </Button>
          </div>
          <div className="menu-icons-nav" onClick={handleClick}>
            <div className={click ? "change bar1" : "bar1"}></div>
            <div className={click ? "change bar2" : "bar2"}></div>
            <div className={click ? "change bar3" : "bar3"}></div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

import './BackTop.css';

function BackTop(props: any) {
  const scrollToTop = () => {
    window.event?.preventDefault();
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <a
        href='/#'
        onClick={() => scrollToTop()}
        className={props.showBackTop ? 'backToTop isActive' : 'backToTop'}
      >
        <div className='arrow up'></div>
      </a>
    </div>
  );
}

export default BackTop;

import React from 'react'
import Cards from '../Cards/Cards'
import './Solutions.css'

function Solutions() {
  return (
    <section className="container-solutions" id="solutions"> 
      <div className="solutions row">
        <h2>Nossas Soluções</h2>
        <Cards />
      </div>
    </section>
  );
}

export default Solutions

import { useState, useEffect } from 'react';
import { Form, Button, Upload, message, DatePicker, Select } from 'antd';
import api from '../../services/api';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { fileTypes } from '../../utils/fileType';

import '../../styles/defaultForm.css';

const UploadFiles = () => {
  const [msg, contextHolder] = message.useMessage();
  const [btndisabled, setbtndisabled] = useState<any>(true);
  const [cnpjs, setCnpjs] = useState([]);

  useEffect(() => {
    const getCnpjs = async () => {
      try {
        const response = await api.get('companies/cnpj');
        setCnpjs(
          response.data.map((cnpj: string) => {
            return {
              value: cnpj,
              label: cnpj,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    getCnpjs();
  }, []);

  const handleSubmit = async (request: FormData) => {
    const hideMessage = message.loading(
      'Aguarde enquanto enviamos os arquivos',
      0
    );
    try {
      const response = await api.post('/blobFiles/upload', request);
      msg.success(response.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.error) msg.error(error.response.data.error);
      else msg.error('Ocorreu um erro, por favor contate o administrador');
    } finally {
      hideMessage();
    }
  };

  const onValuesChange = (_: any, allValues: any) => {
    if (
      !!allValues.date &&
      !!allValues.uploadFiles &&
      allValues.uploadFiles.length > 0 &&
      !!allValues.fileType &&
      !!allValues.cnpj
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const [form] = Form.useForm();
  return (
    <main>
      <div className='containerForm'>
        {contextHolder}
        <Form
          form={form}
          className='form'
          layout='vertical'
          onValuesChange={onValuesChange}
          onFinish={(values) => {
            const date = new Date(values.date);
            const year = moment(date).format('YYYY');
            const month = moment(date).format('MM');
            const body = new FormData();
            values.uploadFiles.forEach((file: any) =>
              body.append('files', file.originFileObj)
            );
            body.append('FileType', values.fileType);
            body.append('Cnpj', values.cnpj);
            body.append('Year', year);
            body.append('Month', values.fileType === 'licencas' ? '01' : month);
            handleSubmit(body);
          }}
        >
          <h1 className='title'>Envio de Arquivos</h1>
          <Form.Item
            valuePropName='fileList'
            getValueFromEvent={(event) => {
              return event?.fileList;
            }}
            label='Arquivos para Upload'
            name={'uploadFiles'}
            rules={[
              {
                required: true,
                message: 'Por favor selecione ao menos um arquivo',
              },
            ]}
          >
            <Upload
              beforeUpload={(file) => {
                //prevent file upload, because it is not needed
                return false;
              }}
              accept='image/png, application/pdf'
            >
              <Button>Selecionar Arquivo</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe o mês e o ano do arquivo',
              },
            ]}
            label='Selecione o mês e ano dos arquivos'
            name={'date'}
          >
            <DatePicker
              locale={locale}
              format='MM/YYYY'
              picker='month'
              placeholder='Selecione a data'
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe o tipo do arquivo',
              },
            ]}
            label='Tipo de arquivo'
            name={'fileType'}
          >
            <Select
              placeholder='Informe o tipo do arquivo'
              options={fileTypes}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor informe o cnpj da empresa',
              },
            ]}
            label='CNPJ'
            name={'cnpj'}
          >
            <Select
              loading={cnpjs.length === 0}
              placeholder='Informe o cnpj da empresa'
              options={cnpjs}
            />
          </Form.Item>

          <Button
            className='submitButton'
            block
            type='primary'
            htmlType='submit'
            disabled={btndisabled}
          >
            Enviar Arquivos
          </Button>
        </Form>
      </div>
    </main>
  );
};

export default UploadFiles;

import Card from '../Card/Card';
import './Cards.css';
import dataCards from './dataCards';

function Cards() {
  return (
    <div className='container-cards'>
      {dataCards.map((item: any, index: number) => {
        return (
          <Card
            key={index}
            title={item.title}
            imgUrl={item.imageUrl}
            text={item.text}
          />
        );
      })}
    </div>
  );
}

export default Cards;

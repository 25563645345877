import './Card.css';

function Card(props: any) {
  return (
    <div className="card">
      <div className="card-img">
        <img src={props.imgUrl} alt="card" />
      </div>
      <div className="card-desc">
        <div className="card-title">
          <h3>{props.title}</h3>
        </div>
        <div className="card-text">
          {props.text.map((item: string, indice: number) => (
            <p key={indice}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;

import React from 'react';
import Accordion from '../../components/AccordionComponent/Accordion';

const UserInventarioResiduos: React.FC = () => {
  return (
    <Accordion title='Inventário de Resíduos' fileType='InventarioResiduos' />
  );
};

export default UserInventarioResiduos;

import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import { message } from 'antd';

export const ResponseInterceptor = () => {
  const navigate = useNavigate();

  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(null, (error) => {
      if (error.response.status === 401) {
        Promise.resolve(error);
        message.error('Sessão expirada');
        navigate('/login');
      } else {
        Promise.resolve(error);
        throw error;
      }
    });
    return () => {
      api.interceptors.response.eject(interceptorId.current as number);
    };
  }, [navigate]);

  return null;
};
